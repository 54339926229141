import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout, PostCard } from '../components/common'
import { MetaData } from '../components/common/meta'

// show default image
const Tag = ({ data, location }) => {
    const tag = data.ghostTag
    const posts = data.allGhostPost.edges
    let image = ``
    switch (true) {
        case (tag.slug === 'architecture'):
            image = "/architecture.png"
            break
        case (tag.slug === 'art'):
            image = "/art.png"
            break
        case (tag.slug.includes('blog')):
            image = "/information.png"
            break
        case (tag.slug === 'is'):
            image = "/information.png"
            break
        case (tag.slug === 'jobs'):
            image = "/jobs.png"
            break
        case (tag.slug === 'landscapes'):
            image = "/landscape.png"
            break
        case (tag.slug.includes('roles')):
            image = "/jobs.png"
            break
        case (tag.slug === 'scribbles'):
            image = "/scribbles.png"
            break
        case (tag.slug === 'education-master-of-architecture'):
            image = "/credentials.png"
            break
        case (tag.slug === 'education-bachelor-of-landscape-architecture'):
            image = "/credentials.png"
            break
        default:
            break;
    }

    let tagSlug
    let postSlug
    let postHtml
    let postToRemove

    // Get the tag slug (remove everything to the first dash)
    tagSlug = [tag.slug].toString().replace(/[^-]*-/,'')

    // Get the post slug that matches the tag slug
    // identified by the initial underscore
    for (let i=0, len=posts.length; i < len; i++) {
        postSlug = posts[i].node.slug.replace(/^_/,'')
        if (postSlug === tagSlug) {
            postHtml = posts[i].node.html
            postToRemove = posts[i].node.slug
        }
    }

    // TODO if postcard slug has underscore, replace with equivalent tag slug 
    
    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="series"
            />

            <Layout>

                {/* Dot Banner */}
                <div>
                    {image && <img className="banner-dot" src={image} alt={tag.name}/>}
                    <h1 className="tag-name">{tag.name.indexOf(`.`) > -1 ? tag.name.replace(`Blog.`,``).split(`.`).pop() : tag.name}</h1>
                </div>

                {/* Post (related specifically to tag) */}
                {postHtml &&
                    <div className="container">
                        {postHtml && <article className="post-feed" dangerouslySetInnerHTML={{__html: postHtml}}></article>}
                    </div>
                }

                {/* Post Feed (without post related to tag) */}
                {posts.length > 1 && 
                    <h3 class="center">Related Posts</h3>
                }
                <div className="container">
                    <article className="tag-feed">
                        {posts.map(({ node }) => {
                            if (node.slug !== postToRemove) {
                                return (<PostCard key={node.id} post={node} />)
                            } else {
                                return ''
                            }
                        })}
                    </article>
                </div>

                <div className="container"><br/></div>

            </Layout>
        </>
    )
}

Tag.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
            feature_image: PropTypes.string,
            slug: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Tag

export const pageQuery = graphql`
	query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
		ghostTag(
			slug: {
				eq: $slug
			}
		) {
			...GhostTagFields
		}
		allGhostPost(
			sort: {
				fields: [published_at]
				order: DESC
			}
			filter: {
				tags: {
					elemMatch: {
						slug: {eq: $slug}
					}
				}
			}
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					...GhostPostFields
				}
			}
		}
	}
`